export const META_TITLE = {
    HOME: 'Livera | Best Interior Designer in Hyderabad - Modern Living',
    CONTACT_US: 'Contact Livera | Leading Interior Designers in Hyderabad',
    ARCHITECTURE_SERVICE: 'Architecture Services in Hyderabad by Livera',
    INTERIOR_DESIGN_SERVICE: 'Top Interior Designers in Hyderabad | Livera',
    RETAIL_DESIGNS_SERVICE: 'Retail Interior Design Services in Hyderabad | Livera',
    LAYOUTS_SERVICE: '2D/3D Layout Services in Hyderabad | Livera Interiors',
    INTER_DESIGN_SERVICE: 'Innovative Interior Design Services in Hyderabad',
    DECORATION_ART_SERVICE: 'Decoration Art Services in Hyderabad | Livera Interiors',
    PROJECTS: 'Livera Design Portfolio | Interior Designers in Hyderabad',
    HOME_THEATER: 'Luxury Home Theater Design in Hyderabad | Livera',
    HOME_AUTOMATION: 'Smart Home Automation in Hyderabad | Livera Interiors',
    FURNITURE_LAYOUTS: 'Furniture Layout Design in Hyderabad | Livera',
    ELECTRIC_LAYOUTS: 'Electrical Layouts in Hyderabad | Modern Interiors by Livera',
    PROJECT_DEVELOPMENT: 'Top Interior Projects in Hyderabad | Livera Interiors',
    404: 'Page Not Found | Explore Livera Interior Design in Hyderabad'
};

export const META_DESCRIPTION = {
    HOME: 'Hyderabad’s top interior design company, Livera specializes in modern home decor and creating stylish, functional interiors for every space.',
    CONTACT_US: 'Get in touch with Livera, Hyderabad’s top interior designers. Transform your space with tailored solutions that reflect your taste and needs.',
    ARCHITECTURE_SERVICE: 'Explore Livera’s architecture services in Hyderabad. Our experts design innovative, functional spaces for homes and offices.',
    INTERIOR_DESIGN_SERVICE: 'Transform your interiors with Livera, Hyderabad’s top designers. We create unique, stylish, and functional spaces for homes and offices.',
    RETAIL_DESIGNS_SERVICE: 'Boost your brand with Livera’s retail design services. Create captivating retail spaces that enhance customer experience in Hyderabad.',
    LAYOUTS_SERVICE: 'Visualize your projects with Livera’s 2D/3D layout services in Hyderabad. Precise designs bring your vision to life.',
    INTER_DESIGN_SERVICE: 'Discover Livera’s interior design services in Hyderabad. We craft unique, stylish spaces for homes, offices, and commercial properties.',
    DECORATION_ART_SERVICE: 'Enhance your Hyderabad home with Livera’s decoration art services. Bespoke designs to elevate your living space with elegance.',
    PROJECTS: 'Explore Livera’s portfolio of interior design and architecture projects in Hyderabad. From homes to offices, discover creative solutions.',
    HOME_THEATER: 'Livera designs luxury home theaters in Hyderabad. Combine style and functionality to create your ultimate entertainment space.',
    HOME_AUTOMATION: 'Upgrade your home with Livera’s automation services in Hyderabad. Integrate smart tech seamlessly into your interiors.',
    FURNITURE_LAYOUTS: 'Livera designs functional, stylish furniture layouts in Hyderabad. Optimize your space with tailored interior solutions.',
    ELECTRIC_LAYOUTS: 'Livera offers advanced electrical layout designs in Hyderabad. Ensure modern, efficient interiors with our expert solutions.',
    PROJECT_DEVELOPMENT: 'From concept to completion, Livera delivers stunning, functional interior design projects in Hyderabad tailored to your vision.',
    404: 'Page not found. Discover Livera’s Hyderabad interior design services. Transform your space with innovative solutions. Contact us for help.'
};
