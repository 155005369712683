import { Link, useLocation } from "react-router-dom";
import { BUSINESS_DETAILS } from "../../constants/social.constants";
import { LANGUAGE } from "../../localization";
import SocialMediaLinks from "../Social-Media-Links/Social-Media-Links";
import { useState } from "react";
import './Navbar.css';

const Navbar = ({ isShowTopNavbar = false }) => {
    const texts = LANGUAGE();
    const { pathname } = useLocation();
    const [isMenuOpen, setIsMenuOpen] = useState(false); // State to track menu open/close
    const [isSubMenuOpen, setIsSubMenuOpen] = useState(false); // State to track menu open/close

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const toggleSubMenu = () => {
        console.log(isSubMenuOpen)
        setIsSubMenuOpen(!isSubMenuOpen);
    };

    return (
        <header id="masthead" className="header ttm-header-style-03 fixed-header">
            {
                isShowTopNavbar ?
                    <div className="top_bar top_bar_on_scroll_hidden ttm-topbar-wrapper ttm-bgcolor-darkgrey clearfix">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-xl-12 d-flex flex-row align-items-center">
                                    <div className="top_bar_contact_item">
                                        <div className="top_bar_icon"></div>{texts?.GOAL_TEXT_TOP_NAVBAR}
                                    </div>
                                    <div className="top_bar_contact_item">
                                        <div className="top_bar_icon"><i className="fa fa-envelope-o"></i>
                                        </div><a href={`mailto:${BUSINESS_DETAILS.mail}`}><span>support: {BUSINESS_DETAILS.mail}</span></a>
                                    </div>
                                    <div className="top_bar_contact_item top_bar_social ml-auto p-0">
                                        <div className="top_bar_icon"><i className="fa fa fa-headphones"></i>
                                        </div>Call Us On: {BUSINESS_DETAILS.phoneNumber}
                                    </div>
                                    <div className="top_bar_contact_item header_btn">
                                        <div className="top_bar_icon"><i className="ti ti-alarm-clock"></i>
                                        </div>Opening Hours: {BUSINESS_DETAILS.openingHours}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : null
            }

            <div id="site-header-menu" className="site-header-menu">
                <div className="site-header-menu-inner ttm-bgcolor-white">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="site-navigation d-flex flex-row align-items-center justify-content-between">
                                    <div className="site-branding">
                                        <Link className="home-link" to="/" title={BUSINESS_DETAILS.businessShortName} rel="home">
                                            <img id="logo-img" width="167" height="53" className="img-fluid" src="/images/logo.svg" alt="logo-livera" loading="lazy" />
                                        </Link>
                                    </div>
                                    <div className="d-flex flex-row">
                                        <div onClick={toggleMenu} className={`menu-toggle-button btn-show-menu-mobile menubar menubar--squeeze ${isMenuOpen ? "is-active" : ""}`}>
                                            <span className="menubar-box">
                                                <span className="menubar-inner"></span>
                                            </span>
                                        </div>
                                        <nav className={`main-menu menu-mobile ${isMenuOpen ? "open" : ""}`} id="menu">
                                            <ul className="menu">
                                                <li className={`mega-menu-item ${pathname === "/" ? "active" : ""}`}>
                                                    <Link to="/">Home</Link>
                                                </li>
                                                <li className={`mega-menu-item ${pathname === "/architecture" ? "active" : ""}`}>
                                                    <Link to="/architecture">Services</Link>
                                                </li>
                                                {/* <li onClick={toggleSubMenu} className={`mega-menu-item ${isSubMenuOpen ? "open" : ""}`}>
                                                    <Link to="#" className={`mega-menu-link ${pathname === "#" ? "active" : ""}`}>Services</Link>
                                                    <ul className={`mega-submenu ${isSubMenuOpen ? "active" : ""}`}>
                                                        <li><Link to="/architecture">Architecture</Link></li>
                                                        <li><Link to="/inter-design">Interior Work</Link></li>
                                                        {/* <li><Link to="/interior">Interior Work</Link></li> *
                                                        <li><Link to="/commercial-design">Commercial Designs</Link></li>
                                                        <li><Link to="/layouts">2D/3D Layouts</Link></li>
                                                        <li><Link to="/decoration-art">Decoration Art</Link></li>
                                                        <li><Link to="/electric-layout">Electric Layout</Link></li>
                                                        <li><Link to="/furniture-layout">Furniture Layout</Link></li>
                                                        <li><Link to="/project-development">Project Development</Link></li>
                                                        <li><Link to="/home-theater">Home Theater</Link></li>
                                                        <li><Link to="/home-automation">Home Automation</Link></li>
                                                    </ul>
                                                </li> */}
                                                <li className={`mega-menu-item ${pathname === "/projects" ? "active" : ""}`}>
                                                    <Link to="/projects">Projects</Link>
                                                </li>
                                                <li className={`mega-menu-item ${pathname === "/contact-us" ? "active" : ""}`}>
                                                    <Link to="/contact-us">Contact Us</Link>
                                                </li>
                                            </ul>
                                        </nav>
                                        <div
                                            className="header_extra d-flex flex-row align-items-center justify-content-end"
                                            style={{ marginLeft: '50px' }}
                                        >
                                            <div className="cmt-widget_header d-flex flex-row justify-content-between">
                                                <div className="widget_info d-flex flex-row align-items-center justify-content-end">
                                                    <div className="social-icons">
                                                        <SocialMediaLinks />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </header>
    );
}

export default Navbar;