import { BUSINESS_DETAILS } from '../../../constants/social.constants';

const About = () => {
    return (
        <section className="ttm-row welcome-section clearfix">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-12">
                        <div className="ttm_single_image-wrapper text-left">
                            <img
                                loading="lazy"
                                width="540"
                                height="610"
                                className="img-fluid"
                                src="/images/about-us-image.jpg"
                                alt="single_01"
                            />
                        </div>
                        <div className="ttm-fid inside ttm-fid-view-lefticon ttm-highlight-fid-style1">
                            <div className="ttm-fid-icon">
                                <i className="flaticon flaticon-navigation"></i>
                            </div>
                            <div className="ttm-fid-contents">
                                <h4 className="ttm-fid-inner">
                                    <span
                                        data-appear-animation="animateDigits"
                                        data-from="0"
                                        data-to={BUSINESS_DETAILS.owner.experience}
                                        data-interval="5"
                                        data-before=""
                                        data-before-style="sup"
                                        data-after="+"
                                        data-after-style="sub"
                                    >
                                        {BUSINESS_DETAILS.owner.experience}
                                    </span>
                                </h4>
                                <h3 className="ttm-fid-title">
                                    <span>Years <br />Of Experiences</span>
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12">
                        <div className="pt-35 res-991-pt-0 res-991-mt-40">
                            <div className="section-title">
                                <div className="title-heade">
                                    <h3>About Our Company</h3>
                                    <h2 className="title">The Best Solutions By Professional Designers</h2>
                                </div>
                            </div>
                            <div className="pb-25 res-991-pb-20">
                                <p>Livera Design is renowned for our bespoke interior design services, where every project receives an extra level of attention to detail. Creative, receptive, and always pushing the boundaries of excellence, we deliver innovative designs that exceed expectations.</p>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor style1">
                                        <li>
                                            <i className="flaticon-work-done ttm-textcolor-skincolor"></i>
                                            <span className="ttm-list-li-content">Project Design and Development</span>
                                        </li>
                                        <li>
                                            <i className="flaticon-work-done ttm-textcolor-skincolor"></i>
                                            <span className="ttm-list-li-content">A Colour Design Services </span>
                                        </li>
                                        <li>
                                            <i className="flaticon-work-done ttm-textcolor-skincolor"></i>
                                            <span className="ttm-list-li-content">An Urban Modern Interior</span>
                                        </li>
                                        <li>
                                            <i className="flaticon-work-done ttm-textcolor-skincolor"></i>
                                            <span className="ttm-list-li-content">Commercial Projects</span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-6">
                                    <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor style1">
                                        <li>
                                            <i className="flaticon-work-done ttm-textcolor-skincolor"></i>
                                            <span className="ttm-list-li-content">Complete Design Development</span>
                                        </li>
                                        <li>
                                            <i className="flaticon-work-done ttm-textcolor-skincolor"></i>
                                            <span className="ttm-list-li-content">Home Lighting Design Plans</span>
                                        </li>
                                        <li>
                                            <i className="flaticon-work-done ttm-textcolor-skincolor"></i>
                                            <span className="ttm-list-li-content">Electrical Interior Planning</span>
                                        </li>
                                        <li>
                                            <i className="flaticon-work-done ttm-textcolor-skincolor"></i>
                                            <span className="ttm-list-li-content">Detail Residential Projects</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;