import { Link } from 'react-router-dom';
import About from '../About/About';
import './Main.css'
// import { PieChart, Pie, Cell, Tooltip } from 'recharts';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { images } from '../../../constants/ourClients.constants';

const HomePageMain = () => {
  const settings = {
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true
  };
  return (
    <div className="site-main">
      <section className="ttm-row zero_padding-section clearfix">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="mt_105 res-991-mt-30">
                <div className="row slick_slider slick-dots-style2 mt-15 res-991-mt-0" data-slick='{"slidesToShow": 3, "slidesToScroll": 1, "arrows":false, "autoplay":false, "dots":false, "infinite":true, "responsive":[{"breakpoint":1199,"settings": {"slidesToShow": 3}}, {"breakpoint":992,"settings":{"slidesToShow": 2}},{"breakpoint":650,"settings":{"slidesToShow": 1}}]}'>
                  <div className="col-lg-4 col-md-4 col-sm-6">
                    <div className="featured-imagebox featured-imagebox-portfolio style4">
                      <div className="featured-thumbnail">
                        <img width="584" height="760" className="img-fluid" src="/images/living-room.jpg" alt="img-alt" loading="lazy" />
                      </div>
                      <Link to="/architecture">
                        <div className="featured-content">
                          <div className="featured-icon">
                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                              <i className="flaticon flaticon-workspace"></i>
                            </div>
                          </div>
                          <div className="featured-title">
                            <h3 style={{ color: '#E8BEAC' }}>Architecture</h3>
                          </div>
                          <div className="featured-desc">
                            <p>We develop the full cycle of project documentation & full details. Our clients satisfaction is most important.</p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-6">
                    <div className="featured-imagebox featured-imagebox-portfolio style4 active">
                      <div className="featured-thumbnail">
                        <img loading="lazy" alt="img" width="584" height="760" className="img-fluid" src="/images/kitchen.jpg" />
                      </div>
                      <Link to="/project-development">
                        <div className="featured-content">
                          <div className="featured-icon">
                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                              <i className="flaticon flaticon-apartment"></i>
                            </div>
                          </div>
                          <div className="featured-title">
                            <h3 style={{ color: '#E8BEAC' }}>Interior Work</h3>
                          </div>
                          <div className="featured-desc">
                            <p>Iterative approaches to corporate strategy foster collaborative thinking to further proposition.</p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-6">
                    <div className="featured-imagebox featured-imagebox-portfolio style4">
                      <div className="featured-thumbnail">
                        <img loading="lazy" width="584" height="760" className="img-fluid" src="/images/modern-styled-entryway.jpg" alt="img" />
                      </div>
                      <Link to="/inter-design">
                        <div className="featured-content">
                          <div className="featured-icon">
                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                              <i className="flaticon flaticon-staircase"></i>
                            </div>
                          </div>
                          <div className="featured-title">
                            <h3 style={{ color: '#E8BEAC' }}>Residential and Commercial Projects</h3>
                          </div>
                          <div className="featured-desc">
                            <p>We will take care of the interior designs, build & management of all kind of living projects.</p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <About />

      <section className="ttm-row services-section ttm-bgcolor-darkgrey bg-img2 clearfix">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="pt-10 text-left">
                <div className="section-title">
                  <div className="title-header">
                    <h3>Best Services</h3>
                    <h2 className="title">Services We’re Providing</h2>
                  </div>
                  <div className="title-desc">
                    <p>Livera Design primarily works in Hyderabad, Bengaluru, and Vijayawada, offering bespoke interior design services for projects of all sizes — from small, intimate spaces to large-scale ventures. Our expertise spans across residential, commercial, and luxury spaces, delivering tailored designs that meet every client's unique needs.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="featured-icon-box icon-align-top-content style2">
                <div className="featured-icon">
                  <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-md">
                    <i className="flaticon flaticon-apartment"></i>
                  </div>
                </div>
                <Link to="/inter-design">
                  <div className="featured-content">
                    <div className="featured-title">
                      <h3>Residential Interior</h3>
                    </div>
                    <div className="featured-desc">
                      <p>We do all types of the interior designing, decoration & furnishing.</p>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="featured-icon-box icon-align-top-content style2">
                <div className="featured-icon">
                  <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-md">
                    <i className="flaticon flaticon-interior-design-1"></i>
                  </div>
                </div>
                <Link to="/architecture">
                  <div className="featured-content">
                    <div className="featured-title">
                      <h3>Custom Solutions</h3>
                    </div>
                    <div className="featured-desc">
                      <p>Our creative 3D artists are always ready to translate your designs.</p>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="featured-icon-box icon-align-top-content style2">
                <div className="featured-icon">
                  <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-md">
                    <i className="flaticon flaticon-bed-1"></i>
                  </div>
                </div>
                <div className="featured-content">
                  <div className="featured-title">
                    <h3>Renovate Rooms</h3>
                  </div>
                  <div className="featured-desc">
                    <p>We are master of renovation & innovation of existing any kind of rooms.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="featured-icon-box icon-align-top-content style2">
                <Link to="/layouts">
                  <div className="featured-icon">
                    <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-md">
                      <i className="flaticon flaticon-decorating"></i>
                    </div>
                  </div>
                  <div className="featured-content">
                    <div className="featured-title">
                      <h3>3D Design Layouts</h3>
                    </div>
                    <div className="featured-desc">
                      <p>We Do All Types Of 2D And 3D design  Computerized Designs.</p>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="featured-icon-box icon-align-top-content style2">
                <div className="featured-icon">
                  <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-md">
                    <i className="flaticon flaticon-door"></i>
                  </div>
                </div>
                <Link to="/commercial-design">
                  <div className="featured-content">
                    <div className="featured-title">
                      <h3>Commercial Interior</h3>
                    </div>
                    <div className="featured-desc">
                      <p>Enforces & strengthens your brand identity by integrating rich experience.</p>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='features-section'>
        <div className='features-child'>
          <div className='features-image'>
            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
              <i className="flaticon flaticon-decorating"></i>
            </div>
          </div>
          <div className='features-text'>
            <h3 style={{ color: 'black' }}>Quality Control</h3>
          </div>
          <div className='features-para'>
            <p>We've team of skilled people with different maintenance experts specialties uncompromised level of </p>
          </div>
        </div>
        <div className='features-child'>
          <div className='features-image'>
            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
              <i className="flaticon flaticon-bed-1"></i>
            </div>
          </div>
          <div className='features-text'>
            <h3 style={{ color: 'black' }}>Focus on quality</h3>
          </div>
          <div className='features-para'>
            <p>Desire to carry activities without depleting resources how long will take you to perform small design. </p>
          </div>
        </div>
        <div className='features-child'>
          <div className='features-image'>
            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
              <i className="flaticon flaticon-apartment"></i></div>
          </div>
          <div className='features-text'>
            <h3 style={{ color: 'black' }}>Certified Mechanics</h3>
          </div>
          <div className='features-para'>
            <p>The outside or all of the external faces of a building, tailored as per requirements of individual one</p>
          </div>
        </div>
        <div className='features-child'>
          <div className='features-image'>
            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
              <i className="flaticon flaticon-interior-design-1"></i>
            </div>
          </div>
          <div className='features-text feature-heading'>
            <h3 style={{ color: 'black' }}>Budget Planning</h3>
          </div>
          <div className='features-para'>
            <p>The variety of tasks that help create safe and comfortable living environment all in your budget </p>
          </div>
        </div>
      </section>

      <section className='ttm-row zero_padding-section bg-layer-equal-height clearfix'>
        <div className="container">
          <div className="row no-gutters">
            <div className='col-lg-6 col-md-12'>
              <div className="col-bg-img-five ttm-bg ttm-col-bgimage-yes ttm-left-span res-991-mt-30">
                <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                <div className="layer-content"></div>
              </div>
              <img loading="lazy" className='ttm-equal-height-image' src="/images/kitchen.jpg" alt="img" />
            </div>
            <div className='col-lg-6 col-md-12'>
              <div className="ttm-bg ttm-col-bgcolor-yes spacing-8 z-index-2">
                <div className="section-title title-style">
                  <div className="title-header">
                    <h3>Why LIVERA ?</h3>
                    <h2 className="title">ADD OUR</h2>
                    <h2 className="title">MOST POPULAR</h2>
                    <h2 className="title">LIVING PROJECT</h2>
                  </div>
                </div>
                <p>
                  Livera is a distinguished name in the realm of interior design, boasting an impressive 15 years of unparalleled expertise in transforming spaces into extraordinary living environments. Our journey is marked by a trail of successfully completed projects that stand as testaments to our commitment to excellence and innovation. What sets Livera apart is not merely the duration of our experience but the passion and creativity we infuse into every project.
                </p>
                <Link className='ttm-btn ttm-btn-size-md ttm-btn-shape-squar ttm-btn-style-fill ttm-icon-btn-right ttm-btn-color-skincolor' to="/contact-us">Contact Us</Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="ttm-row zero_padding-section bg-layer-equal-height clearfix">
        <div className="container">
          <div className="row no-gutters">
            <div className="col-lg-6 col-md-12">
              <div className="ttm-bg ttm-col-bgcolor-yes spacing-7 z-index-2">
                <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                <div className="layer-content">
                  <div className="section-title">
                    <div className="title-header">
                      <h3>Feature</h3>
                      <h2 className="title">Interior Designs From The Future Living</h2>
                    </div>
                    <p>Each project is designed with close attention to detail, managed & implemented with great care. We work with craftsmen and bespoke furniture makers as required to create seamless, well-executed on budget.</p>
                  </div>
                  {/* <div className="row ttm-boxes-spacing-20px">
                    <div className="col-md-4 ttm-box-col-wrapper">
                      <div className="featured-icon-box icon-align-top-content style3">
                        <div className="featured-icon">
                          <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-md">
                            <i className="flaticon flaticon-decorating"></i>
                          </div>
                        </div>
                        <div className="featured-content">
                          <div className="featured-title">
                            <h3>Interior Design</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 ttm-box-col-wrapper">
                      <div className="featured-icon-box icon-align-top-content style3">
                        <div className="featured-icon">
                          <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-md">
                            <i className="flaticon flaticon-windows"></i>
                          </div>
                        </div>
                        <div className="featured-content">
                          <div className="featured-title">
                            <h3>Minimal Design</h3>
                          </div>
                        </div>
                      </div>
                    </div> 
                  </div>*/}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="col-bg-img-four ttm-bg ttm-col-bgimage-yes ttm-right-span">
                <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                <div className="layer-content"></div>
              </div>
              <img loading="lazy" src="/images/office.jpg" className="ttm-equal-height-image" alt="bgimage" />
            </div>
          </div>
        </div>
      </section>

      {/* <section className="ttm-row zero_padding-section bg-layer-equal-height clearfix">
        <div className="container">
          <div className="row no-gutters">
            <div className="col-lg-6">
              <div className="col-bg-img-five ttm-bg ttm-col-bgimage-yes ttm-left-span res-991-mt-30">
                <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                <div className="layer-content"></div>
              </div>
              <img src="/images/bedroom-2.jpg" className="ttm-equal-height-image" alt="bgimage" />
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="ttm-bg ttm-col-bgcolor-yes spacing-8 z-index-2">
                <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                <div className="layer-content">
                  <div className="section-title">
                    <div className="title-header">
                      <h3>Facts & Digit</h3>
                      <h2 className="title">Our Innovative Ideas, The Most Stylish designs</h2>
                    </div>
                    <p>A comprehensive design service, creating beautifully crafted interiors for discerning clients. We provide a large number of people better and luxurious living homes and basic design development.</p>
                  </div>
                  <div className="ttm-bgcolor-white spacing-9 box-shadow">
                    <div className="row">
                      <div className="col-md-3 col-sm-6">
                        <h3 style={{ paddingLeft: 8 }}>Our Strengths</h3>
                      </div>
                    </div>
                    <div className="row charts">
                      <div className="col-md-3 col-sm-6">
                        <div className="progress-chart-container">
                          <PieChart width={200} height={200}>
                            <Pie
                              data={[{ value: 96 }, { value: 4 }]}
                              dataKey="value"
                              cx="50%"
                              cy="50%"
                              innerRadius={80}
                              outerRadius={90}
                              startAngle={90}
                              endAngle={-270}
                              paddingAngle={0}
                              label={false}
                            >
                              {[{ value: 96 }, { value: 4 }].map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={index === 0 ? '#CDA274' : '#ECECEC'} />
                              ))}
                            </Pie>
                            <Tooltip />
                            <text
                              x="50%"
                              y="50%" // Position the label in the center
                              textAnchor="middle"
                              dominantBaseline="middle"
                              fontSize="36"
                            >
                              96%
                            </text>
                          </PieChart>
                          <h5 style={{ textAlign: 'center' }}>Interior design</h5>
                        </div>
                      </div>

                      <div className="col-md-3 col-sm-6">
                        <div className="progress-chart-container">
                          <PieChart width={200} height={200}>
                            <Pie
                              data={[{ value: 95 }, { value: 5 }]}
                              dataKey="value"
                              cx="50%"
                              cy="50%"
                              innerRadius={80}
                              outerRadius={90}
                              startAngle={90}
                              endAngle={-270}
                              paddingAngle={0}
                              label={false}
                            >
                              {[{ value: 95 }, { value: 5 }].map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={index === 0 ? '#CDA274' : '#ECECEC'} />
                              ))}
                            </Pie>
                            <Tooltip />
                            <text
                              x="50%"
                              y="50%" // Position the label in the center
                              textAnchor="middle"
                              dominantBaseline="middle"
                              fontSize="36"
                            >
                              95%
                            </text>
                          </PieChart>
                          <h5 style={{ textAlign: 'center' }}>3D Design Layouts</h5>
                        </div>
                      </div>

                      <div className="col-md-3 col-sm-6">
                        <div className="progress-chart-container">
                          <PieChart width={200} height={200}>
                            <Pie
                              data={[{ value: 75 }, { value: 25 }]}
                              dataKey="value"
                              cx="50%"
                              cy="50%"
                              innerRadius={80}
                              outerRadius={90}
                              startAngle={90}
                              endAngle={-270}
                              paddingAngle={0}
                              label={false}
                            >
                              {[{ value: 75 }, { value: 25 }].map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={index === 0 ? '#CDA274' : '#ECECEC'} />
                              ))}
                            </Pie>
                            <Tooltip />
                            <text
                              x="50%"
                              y="50%" // Position the label in the center
                              textAnchor="middle"
                              dominantBaseline="middle"
                              fontSize="36"
                            >
                              75%
                            </text>
                          </PieChart>
                          <h5 style={{ textAlign: 'center' }}>Exterior design</h5>
                        </div>
                      </div>

                      <div className="col-md-3 col-sm-6">
                        <div className="progress-chart-container">
                          <PieChart width={200} height={200}>
                            <Pie
                              data={[{ value: 98 }, { value: 2 }]}
                              dataKey="value"
                              cx="50%"
                              cy="50%"
                              innerRadius={80}
                              outerRadius={90}
                              startAngle={90}
                              endAngle={-270}
                              paddingAngle={0}
                              label={false}
                            >
                              {[{ value: 98 }, { value: 2 }].map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={index === 0 ? '#CDA274' : '#ECECEC'} />
                              ))}
                            </Pie>
                            <Tooltip />
                            <text
                              x="50%"
                              y="50%" // Position the label in the center
                              textAnchor="middle"
                              dominantBaseline="middle"
                              fontSize="36"
                            >
                              98%
                            </text>
                          </PieChart>
                          <h5 style={{ textAlign: 'center' }}>Our Team</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section > */}

      <section className='ourClients'>
        <div className="section-title title-style-center_text">
          <div className="title-header">
            <h3>Our Clients</h3>
          </div>
        </div>
        <div className="image-slider">
          <Slider {...settings}>
            {images.map((image, index) => (
              <a href={image.link} target="_blank" rel="noreferrer">
                <div key={index}>
                  <img loading="lazy" className='companyLogo' src={image.img} alt="logo" />
                </div>
              </a>
            ))}
          </Slider>
        </div>
      </section>
    </div >
  );
}

export default HomePageMain;