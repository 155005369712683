import { Link } from 'react-router-dom';
import Layout from '../Layout/Layout';
import SideBar from './SideBar';

const Layout1 = (props: any) => {
  return (
    <Layout title={props.info.metaTitle} description={props.info.metaDescription}>
      <div className="ttm-page-title-row">
        <div className="ttm-page-title-row-inner">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12">
                <div className="page-title-heading">
                  <h2 className="title">{props.info.title}</h2>
                </div>
                <div className="breadcrumb-wrapper">
                  <div className="container">
                    <div className="breadcrumb-wrapper-inner">
                      <span>
                        <Link title="Go to Home." to="/" className="home"><i className="themifyicon ti-home"></i>&nbsp;&nbsp;Home</Link>
                      </span>
                      <span className="ttm-bread-sep">&nbsp; / &nbsp;</span>
                      <span>{props.info.title}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="site-main">
        <div className="ttm-row sidebar ttm-sidebar-left ttm-bgcolor-white clearfix">
          <div className="container">
            <div className="row">
              <SideBar name={props.info.name} />
              <div className="col-lg-8 content-area">
                <article className="ttm-service-single-content-area">
                  <h2>{props.info.mainHeading}</h2>
                  <div className="ttm-service-featured-wrapper ttm-featured-wrapper">
                    <div className="ttm_single_image-wrapper pt-20 mb-20 res-991-mb-30">
                      <img width="799" height="350" className="img-fluid" src={props.info.image1} alt="project-9" loading="lazy"
                        style={{
                          height: '450px',
                          width: '799px',
                          objectFit: 'cover'
                        }}
                      />
                    </div>
                  </div>
                  <div className="ttm-service-classic-content">
                    {props.info.mainParagraphs.map((e: string) => {
                      return <p key={e}>{e}</p>
                    })}
                    {
                      props.info.points.map((e: any) => {
                        return (
                          <div className={`${e.className}`}>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="text-content">
                                  <div className="icon-box"><i className="fa fa-check-square-o ttm-textcolor-skincolor"></i></div>
                                  <div className="icon-content">
                                    <h3>{e.title}</h3>
                                    <p>{e.description}</p>
                                  </div>
                                </div>
                                <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor style2">
                                  {e.bulletPoints.map((e: string) => {
                                    return (
                                      <li key={Math.random()}><i className="flaticon-work-done ttm-textcolor-skincolor"></i>
                                        <span className="ttm-list-li-content">{e}</span>
                                      </li>
                                    )
                                  })}
                                </ul>
                              </div>
                              <div className="col-md-6">
                                <div className="ttm_single_image-wrapper res-767-pt-30">
                                  <img loading="lazy" width="385" height="224" className="img-fluid" src={e.image} alt="single_image-10" />
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })
                    }
                    {props.info.reviews && <>
                      <div className="pt-30">
                        <h2>Words From Our Customers</h2>
                      </div>
                      <div className="row slick_slider slick-dots-style2" data-slick='{"slidesToShow": 2, "slidesToScroll": 1, "arrows":false, "autoplay":false, "dots":false, "infinite":true, "responsive":[{"breakpoint":1199,"settings": {"slidesToShow": 3}}, {"breakpoint":992,"settings":{"slidesToShow": 2}},{"breakpoint":620,"settings":{"slidesToShow": 1}}]}'>
                        {props.info.reviews.map((e: any, i: number) => {
                          return (
                            <div key={Math.random()} className="col-lg-6">
                              <div className="testimonials style1">
                                <div className="testimonials-inner-item">
                                  <div className="testimonial-content">
                                    <div className="ttm-ratting-star">
                                      {Array.from({ length: props.info.reviews[i].stars }, (_, index) => index).map((e) => <i className="fa fa-star"></i>)}
                                    </div>
                                    <blockquote>{props.info.reviews[i].review}</blockquote>
                                    <div className="testimonial-avatar">
                                      <div className="testimonial-img">
                                        <img loading="lazy" width="150" height="150" className="img-fluid" src="https://via.placeholder.com/150x150/808080?text=150x150+01.jpg" alt="testimonial-img" />
                                      </div>
                                      <div className="testimonial-caption">
                                        <h3>{props.info.reviews[i].name}</h3>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </>}
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Layout1