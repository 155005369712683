import aparna from '../assets/images/componyLogos/aparna.jpeg';
import honor from '../assets/images/componyLogos/honor.jpeg';
import lancohills from '../assets/images/componyLogos/lancohills.jpeg';
import lansum from '../assets/images/componyLogos/lansum.jpeg';
import midvalleycity from '../assets/images/componyLogos/midvalleycity.jpeg';
import myhomegroup from '../assets/images/componyLogos/myhomegroup.jpeg';
import poulomi from '../assets/images/componyLogos/poulomi.jpeg';
import raintreepark from '../assets/images/componyLogos/raintreepark.jpeg';
import urjith from '../assets/images/componyLogos/urjith.jpeg';

export const images = [
  { img: aparna, link: "https://www.aparnaconstructions.com/" },
  { img: honor, link: "https://honerprojects.com/properties.php?utm_source=Google&utm_medium=Search&utm_campaign=Kukatpally-Locality-Search-Nov-23&placement=&keyword=gated%20community%20apartments%20in%20hitech%20city&gad_source=1&gclid=EAIaIQobChMIyt_ZlqmnigMVdKhmAh3GgzPpEAAYASAAEgJHpfD_BwE" },
  { img: lancohills, link: "https://www.lancohills.com/" },
  { img: lansum, link: "https://lansumproperties.com/" },
  { img: midvalleycity, link: "http://www.midvalleycity.in/" },
  { img: myhomegroup, link: "https://www.myhomeconstructions.com/" },
  { img: poulomi, link: "https://www.poulomi.in/" },
  { img: raintreepark, link: "https://www.justdial.com/Hyderabad/Raintree-Park-Phase-I-Kukatpally/040PXX40-XX40-181024210512-P1P1_BZDET" },
  { img: urjith, link: "https://www.99acres.com/independent-house-for-rent-in-vision-avenues-urjith-tellapur-hyderabad-92166-rnpffid" }
]