import { Link } from 'react-router-dom'
import arch10 from '../../assets/images/arch10.jpg';
import arch11 from '../../assets/images/arch11.jpg';
import arch12 from '../../assets/images/arch12.jpg';
import arch5 from '../../assets/images/arch5.jpg';
import arch6 from '../../assets/images/arch6.jpg';
import arch7 from '../../assets/images/arch7.jpg';
import arch8 from '../../assets/images/arch8.jpg';
import arch9 from '../../assets/images/arch9.jpg';
import Contact from '../Services/contact/Contact';

interface Props {
  name: string,
}

const SideBar = (props: Props) => {
  return (
    <>
      <div className="col-lg-4 widget-area sidebar-left">
        <aside className="widget widget-nav-menu">
          <h3 className="widget-title">More Services</h3>
          <ul className="widget-menu">
            <li className={`${props.name === "architecture" ? "active" : ""}`}><Link to="/architecture">Architecture</Link></li>
            <li className={`${props.name === "inter" ? "active" : ""}`}><Link to="/inter-design">Residential Projects</Link></li>
            {/* <li className={`${props.name === "interior" ? "active" : ""}`}><Link to="/interior">Interior Work</Link></li> */}
            <li className={`${props.name === "retail" ? "active" : ""}`}><Link to="/commercial-design">Commercial Designs</Link></li>
            <li className={`${props.name === "layouts" ? "active" : ""}`}><Link to="/layouts">2D/3D Layouts</Link></li>
            <li className={`${props.name === "decoration" ? "active" : ""}`}><Link to="/decoration-art">Decoration art</Link></li>
            <li className={`${props.name === "electric-layout" ? "active" : ""}`}><Link to="/electric-layout">Electric Layout</Link></li>
            <li className={`${props.name === "furniture" ? "active" : ""}`}><Link to="/furniture-layout">Furniture Layout</Link></li>
            <li className={`${props.name === "devlopment" ? "active" : ""}`}><Link to="/project-development">Project Development</Link></li>
            <li className={`${props.name === "home-theater" ? "active" : ""}`}><Link to="/home-theater">Home Theater</Link></li>
            <li className={`${props.name === "home-automation" ? "active" : ""}`}><Link to="/home-automation">Home Automation</Link></li>
          </ul>
        </aside>
        <Contact />
        <aside className="widget widget-archive">
          <h3 className="widget-title">Gallery</h3>
          <div id="gallery-2" className="gallery-wrapper">
            <figure className="gallery-item">
              <div className="gallery-icon landscape">
                <img width="150" height="150" className="img-fluid" src={arch5} alt="gellary_img" loading="lazy" />
              </div>
            </figure>
            <figure className="gallery-item">
              <div className="gallery-icon landscape">
                <img width="150" height="150" className="img-fluid" src={arch5} alt="gellary_img" loading="lazy" />
              </div>
            </figure>
            <figure className="gallery-item">
              <div className="gallery-icon landscape">
                <img width="150" height="150" className="img-fluid" src={arch6} alt="gellary_img" loading="lazy" />
              </div>
            </figure>
            <figure className="gallery-item">
              <div className="gallery-icon landscape">
                <img width="150" height="150" className="img-fluid" src={arch7} alt="gellary_img" loading="lazy" />
              </div>
            </figure>
            <figure className="gallery-item">
              <div className="gallery-icon landscape">
                <img width="150" height="150" className="img-fluid" src={arch8} alt="gellary_img" loading="lazy" />
              </div>
            </figure>
            <figure className="gallery-item">
              <div className="gallery-icon landscape">
                <img width="150" height="150" className="img-fluid" src={arch9} alt="gellary_img" loading="lazy" />
              </div>
            </figure>
            <figure className="gallery-item">
              <div className="gallery-icon landscape">
                <img width="150" height="150" className="img-fluid" src={arch10} alt="gellary_img" loading="lazy" />
              </div>
            </figure>
            <figure className="gallery-item">
              <div className="gallery-icon landscape">
                <img width="150" height="150" className="img-fluid" src={arch11} alt="gellary_img" loading="lazy" />
              </div>
            </figure>
            <figure className="gallery-item">
              <div className="gallery-icon landscape">
                <img width="150" height="150" className="img-fluid" src={arch12} alt="gellary_img" loading="lazy" />
              </div>
            </figure>
          </div>
        </aside>
      </div>
    </>
  )
}

export default SideBar