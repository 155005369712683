import { Link } from 'react-router-dom';
import main from '../../assets/images/services/retail/main.jpg';
import Layout from '../Layout/Layout';
import { META_DESCRIPTION, META_TITLE } from '../../constants/seo.constants';
import SideBar from '../ServicesLayout/SideBar';

export const RetailDesign = () => {
  return (
    <Layout title={META_TITLE.RETAIL_DESIGNS_SERVICE} description={META_DESCRIPTION.RETAIL_DESIGNS_SERVICE}>
      <div className="ttm-page-title-row">
        <div className="ttm-page-title-row-inner">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12">
                <div className="page-title-heading">
                  <h2 className="title">Commercial Designs</h2>
                </div>
                <div className="breadcrumb-wrapper">
                  <div className="container">
                    <div className="breadcrumb-wrapper-inner">
                      <span>
                        <Link title="Go to Home." to="/" className="home"><i className="themifyicon ti-home"></i>&nbsp;&nbsp;Home</Link>
                      </span>
                      <span className="ttm-bread-sep">&nbsp; / &nbsp;</span>
                      <span>Commercial Designs</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="site-main">

        <div className="ttm-row sidebar ttm-sidebar-left pb-45 ttm-bgcolor-white clearfix">
          <div className="container">
            <div className="row">
              <SideBar name='retail' />
              <div className="col-lg-8 content-area">
                <article className="ttm-service-single-content-area">
                  <div className="ttm-service-featured-wrapper ttm-featured-wrapper">
                    <div className="ttm_single_image-wrapper mb-40 res-991-mb-30">
                      <img width="1200" height="800" className="img-fluid" src={main} alt="project-1" loading="lazy"
                        style={{
                          height: '500px',
                          width: '799px',
                          objectFit: 'cover'
                        }}
                      />
                    </div>
                  </div>
                  <div className="ttm-service-classic-content">
                    <h2>Be fabulous. Be empowered. Be Blown Away!</h2>
                    <p>What sets Livera apart is our unwavering commitment to quality, innovation, and client satisfaction. Our approach is rooted in collaboration, creativity, and attention to detail. </p>
                    <p>: Timely execution, quality craftsmanship, and meticulous attention to detail are the hallmarks of every Livera project.our needs, preferences, and budget are at the heart of our design process. We aim to exceed your expectations in every way</p>
                    <div className="pt-20">
                      <h2>Service Advantages</h2>
                      <p>Our needs, preferences, and budget are at the heart of our design process. We aim to exceed your expectations in every way Livera's designs not only enhance your living or working space but also add long-lasting value to your property. It's an investment in both your present and future</p>
                    </div>
                    <div className="ttm-bgcolor-white pt-15 pb-25">
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6">
                          <div className="featured-icon-box icon-align-top-content style2">
                            <div className="featured-icon">
                              <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-md">
                                <i className="flaticon flaticon-blueprint"></i>
                              </div>
                            </div>
                            <div className="featured-content">
                              <div className="featured-title">
                                <h3>Qualified Candidates</h3>
                              </div>
                              <div className="featured-desc">
                                <p>Livera's designs not only enhance your living or working space but also add long-lasting value to your property. It's an investment in both your present and future</p>
                              </div>
                              <div className="ttm-footer">
                                {/* <a className="ttm-btn btn-inline ttm-btn-size-md ttm-icon-btn-right ttm-btn-color-darkgrey" href="our-team.html">Read More</a> */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6">
                          <div className="featured-icon-box icon-align-top-content style2">
                            <div className="featured-icon">
                              <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-md">
                                <i className="flaticon flaticon-destination"></i>
                              </div>
                            </div>
                            <div className="featured-content">
                              <div className="featured-title">
                                <h3>Best Platform</h3>
                              </div>
                              <div className="featured-desc">
                                <p>Whether you're seeking a home that reflects your personality, an office that inspires creativity, or a commercial space that captivates your customers, Livera is your partner in design excellence.</p>
                              </div>
                              <div className="ttm-footer">
                                {/* <a className="ttm-btn btn-inline ttm-btn-size-md ttm-icon-btn-right ttm-btn-color-darkgrey" href="contact-us.html">Read More</a> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <h2>Our Latest Project</h2>
                    <p>Contrary to popular belief, Lorem Ipsum is not simply <u><a className="ttm-textcolor-skincolor" href="/">random text.</a></u> It has roots in a <strong><em>piece of classical</em></strong> Latin literature from 45 BC, making it over 2000 years old.</p> */}
                    {/* <div className="row slick_slider" data-slick='{"slidesToShow": 2, "slidesToScroll": 1, "arrows":false, "autoplay":false, "dots":false, "infinite":true, "responsive":[{"breakpoint":992,"settings":{"slidesToShow": 2}},{"breakpoint":620,"settings":{"slidesToShow": 1}}]}'>
                      <div className="col-lg-6 col-md-6 col-sm-6">
                        <div className="featured-imagebox featured-imagebox-portfolio style3">
                          <div className="featured-thumbnail">
                            <img loading="lazy" width="610" height="750" className="img-fluid" src={arch2} alt="" />
                          </div>
                          <div className="featured-content-inner">
                            <div className="featured-content">
                              <div className="featured-title">
                                <h3><Link to="/" tabIndex={0}>Modern Kitchen</Link></h3>
                              </div>
                              <div className="featured-desc">
                                <p>The style of the interior was defined as modern in light colors. Main materials used in project are wallpaper etc.</p>
                              </div>
                            </div>
                            <div className="ttm-footer">
                              <a className="ttm-btn btn-inline ttm-btn-size-md ttm-icon-btn-right ttm-btn-color-dark" href="/" tabIndex={0}>Read More<i className="ti ti-plus"></i></a> 
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6">
                        <div className="featured-imagebox featured-imagebox-portfolio style3">
                          <div className="featured-thumbnail">
                            <img loading="lazy" width="610" height="750" className="img-fluid" src={arch3} alt="" />
                          </div>
                          <div className="featured-content-inner">
                            <div className="featured-content">
                              <div className="featured-title">
                                <h3><Link to="/" tabIndex={0}>Interior Work</Link></h3>
                              </div>
                              <div className="featured-desc">
                                <p>The entire interior is the beginning of the history of the house, such as colors, lighting, and materials.</p>
                              </div>
                            </div>
                            <div className="ttm-footer">
                              <a className="ttm-btn btn-inline ttm-btn-size-md ttm-icon-btn-right ttm-btn-color-dark" href="/" tabIndex={0}>Read More<i className="ti ti-plus"></i></a> 
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6">
                        <div className="featured-imagebox featured-imagebox-portfolio style3">
                          <div className="featured-content-inner">
                            <div className="featured-content">
                              <div className="featured-title">
                                <h3><Link to="/" tabIndex={0}>Modern Kitchen</Link></h3>
                              </div>
                              <div className="featured-desc">
                                <p>The style of the interior was defined as modern in light colors. Main materials used in project are wallpaper etc.</p>
                              </div>
                            </div>
                            <div className="ttm-footer">
                              <a className="ttm-btn btn-inline ttm-btn-size-md ttm-icon-btn-right ttm-btn-color-dark" href="/" tabIndex={0}>Read More<i className="ti ti-plus"></i></a> 
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
